:root {
  --window-height: 100%;
}

::selection {
  color: #e8e5df;
  background: #5f6d67;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: grid;
  height: var(--window-height);
  margin: 0;
  padding: 0;
  color: #333;

  font-family: Georgia, Times, serif;
  background-image: url('https://res.cloudinary.com/dstnkzm7y/image/upload/c_fill,h_1024,q_auto:low,w_768/v1616010433/nillaberko.se/bg_hdrgtp.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  place-items: center;

  @media screen and (min-width: 768px) {
    background-image: url('https://res.cloudinary.com/dstnkzm7y/image/upload/c_fill,h_1080,q_auto:low,w_1920/v1616010433/nillaberko.se/bg_hdrgtp.jpg');
  }
}

.info {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 48px 32px;
  background-color: rgba(#e8e5df, 0.8);

  &::before,
  &::after {
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: -1;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background: rgba(#b28b60, 0.6);
    transform: rotate(2deg);
    content: "";
  }

  &::before {
    animation: beforespin 20s linear infinite;
  }

  &::after {
    z-index: -2;
    background: rgba(#a5afa8, 0.6);
    transform: rotate(-2deg);
    animation: afterspin 20s linear infinite;
  }
}

h1,
h2 {
  margin: 0;
  font-weight: 400;
  font-family: 'Fjalla One', sans-serif;
  text-transform: uppercase;
}

h1 {
  font-size: 48px;

  @media screen and (min-width: 400px) {
    font-size: 64px;
  }
}

h2 {
  font-size: 24px;

  @media screen and (min-width: 400px) {
    font-size: 32px;
  }
}

a {
  margin: 8px 0;
  color: #333;
  text-decoration: none;

  @media screen and (min-width: 400px) {
    font-size: 20px;
  }

  &:hover,
  &:focus {
    color: #846e5e;
  }
}

@keyframes beforespin {
  0% {
    transform: rotate(-3deg);
    opacity: 0.8;
  }

  33% {
    opacity: 1;
  }

  50% {
    transform: rotate(3deg);
  }

  100% {
    transform: rotate(-3deg);
    opacity: 0.8;
  }
}

@keyframes afterspin {
  0% {
    transform: rotate(4deg);
    opacity: 0.8;
  }

  50% {
    transform: rotate(-4deg);
    opacity: 1;
  }

  100% {
    transform: rotate(4deg);
    opacity: 0.8;
  }
}
