:root {
  --window-height: 100%;
}

::-moz-selection {
  color: #e8e5df;
  background: #5f6d67;
}

::selection {
  color: #e8e5df;
  background: #5f6d67;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  display: grid;
  height: var(--window-height);
  margin: 0;
  padding: 0;
  color: #333;
  font-family: Georgia, Times, serif;
  background-image: url("https://res.cloudinary.com/dstnkzm7y/image/upload/c_fill,h_1024,q_auto:low,w_768/v1616010433/nillaberko.se/bg_hdrgtp.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  place-items: center;
}

@media screen and (min-width: 768px) {
  body {
    background-image: url("https://res.cloudinary.com/dstnkzm7y/image/upload/c_fill,h_1080,q_auto:low,w_1920/v1616010433/nillaberko.se/bg_hdrgtp.jpg");
  }
}

.info {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 48px 32px;
  background-color: rgba(232, 229, 223, 0.8);
}

.info::before, .info::after {
  position: absolute;
  top: -4px;
  left: -4px;
  z-index: -1;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  background: rgba(178, 139, 96, 0.6);
  -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
  content: "";
}

.info::before {
  -webkit-animation: beforespin 20s linear infinite;
          animation: beforespin 20s linear infinite;
}

.info::after {
  z-index: -2;
  background: rgba(165, 175, 168, 0.6);
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
  -webkit-animation: afterspin 20s linear infinite;
          animation: afterspin 20s linear infinite;
}

h1,
h2 {
  margin: 0;
  font-weight: 400;
  font-family: "Fjalla One", sans-serif;
  text-transform: uppercase;
}

h1 {
  font-size: 48px;
}

@media screen and (min-width: 400px) {
  h1 {
    font-size: 64px;
  }
}

h2 {
  font-size: 24px;
}

@media screen and (min-width: 400px) {
  h2 {
    font-size: 32px;
  }
}

a {
  margin: 8px 0;
  color: #333;
  text-decoration: none;
}

@media screen and (min-width: 400px) {
  a {
    font-size: 20px;
  }
}

a:hover, a:focus {
  color: #846e5e;
}

@-webkit-keyframes beforespin {
  0% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
    opacity: 0.8;
  }
  33% {
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  100% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
    opacity: 0.8;
  }
}

@keyframes beforespin {
  0% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
    opacity: 0.8;
  }
  33% {
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg);
  }
  100% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
    opacity: 0.8;
  }
}

@-webkit-keyframes afterspin {
  0% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    opacity: 0.8;
  }
}

@keyframes afterspin {
  0% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    opacity: 0.8;
  }
  50% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
    opacity: 0.8;
  }
}
/*# sourceMappingURL=index.0a3af966.css.map */
